/* Fonts */
@import "partials/_strawford-italic.css";
@import "partials/_strawford-roman.css";
@import "partials/_poppins.css";
@import "partials/_prism-one-dark.css";

/* Tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
  *:focus {
    @apply outline-none;
  }

  body {
    @apply font-body font-medium leading-normal;
    @apply dark:text-gray-100;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-display font-medium text-gray-900;
    @apply dark:text-gray-100;
  }

  h1 {
    @apply text-5xl md:text-6xl font-black;

    span {
      @apply block text-4xl md:text-5xl mt-6;
    }
  }

  h2 {
    @apply inline-block relative text-5xl font-black text-gray-800 mb-6 z-1;
    @apply after:block after:absolute after:w-full after:h-3 after:bottom-1 after:bg-yellow-500 after:-z-1;
    @apply dark:after:bg-blue-700;
  }

  h3 {
    @apply inline-block relative text-3xl font-bold mb-4 z-1;
    @apply after:block after:absolute after:w-full after:h-1 after:bottom-1 after:bg-blue-600 after:-z-1;
    @apply dark:after:bg-yellow-500;
  }

  h4 {
    @apply text-xl font-bold mb-2;
  }

  a {
    @apply inline-block transition duration-300 cursor-pointer;

    &:not(header a, footer a) {
      @apply font-bold;
      &:not(.btn) {
        @apply text-blue-600 hover:text-blue-800 focus:text-blue-800;
        @apply dark:text-blue-500 dark:hover:text-blue-300 dark:focus:text-blue-300;
      }
    }
  }

  blockquote {
    @apply relative rounded-xl py-4 pl-7 pr-4 text-gray-900 bg-gray-100;
    @apply before:block before:absolute before:rounded-xl before:w-1 before:left-3 before:top-3 before:bg-yellow-500;
    @apply dark:text-gray-100 dark:bg-gray-700;

    &:before {
      height: calc(100% - 1.5rem);
    }

    cite {
      @apply not-italic text-sm text-gray-700;
      @apply before:inline-block before:mr-2;
      @apply dark:text-gray-200;

      &:before {
        content: "-";
      }

      em {
        @apply font-bold;
      }
    }

    p {
      @apply !my-0;
    }
  }

  code {
    &:not(pre code) {
      @apply bg-gray-900 text-gray-100 px-2 py-1 rounded-md;
    }
  }

  textarea {
    @apply resize-none h-80;
  }
}

@layer utilities {
  .grid-auto-fit {
    align-item: center;
  }

  .list {
    &-dash {
      li {
        @apply relative pl-6 ml-1 mb-2 last:mb-0;
        @apply before:block before:w-3 before:h-1 before:rounded-xl before:absolute before:left-0 before:top-2.5 before:bg-gray-700;
        @apply dark:before:bg-gray-300;
      }
    }
  }

  .prose {
    max-width: 80ch;
    @apply leading-7;

    & > * {
      @apply mb-6;
    }
  }

  @variants responsive {
    .prose-lg {
      @apply text-lg;
    }
  }

  @variants hover, focus, focus-within, hocus, hocus-within {
    .layer {
      &-none {
        @apply bg-transparent shadow-none;
      }

      &-overflow {
        @apply bg-white;
        @apply dark:bg-gray-900;
      }

      &-base {
        @apply bg-gray-100;
        /* https://www.heropatterns.com/ - texture */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23BFBFC2' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
      }

      &-surface {
        @apply bg-white shadow-md;
        @apply dark:bg-gray-800;

        /* ! These styles do not automatically apply to .cta due to nesting. Apply manually when changed. */
        &.card {
          @apply my-8 px-4 py-8 md:px-8 md:py-12;
        }

        &.reverse {
          @apply bg-gray-900 text-white;
          @apply dark:bg-gray-100 dark:text-gray-900;
        }
      }

      &-overlay-low {
        @apply bg-gray-100 shadow-md;
        @apply dark:bg-gray-700;
      }

      &-overlay-high {
        @apply bg-gray-200 shadow-md;
        @apply dark:bg-gray-600;
      }

      &-highlight {
        @apply bg-gray-900;
        /* https://www.heropatterns.com/ - tic tac toe */
        background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%23f6f6f6' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }
  }

  .card {
    @apply p-2 rounded-xl;
  }

  .form-control {
    @apply last:mb-0;

    label {
      @apply block mb-1 font-bold;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      @apply block w-full py-2 px-4 rounded-xl transition duration-300 border-2 border-gray-300;
      @apply hocus:border-gray-900 hocus:shadow-md;
      @apply focus:ring-4 focus:ring-gray-400;
      @apply dark:bg-gray-700 dark:border-gray-600;
    }
  }

  .dark {
    .layer {
      &-base {
        @apply bg-gray-900;
        /* https://www.heropatterns.com/ - texture */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2336353e' fill-opacity='1' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
      }

      &-highlight {
        @apply bg-gray-600;
        background-image: url("data:image/svg+xml,%3Csvg width='64' height='64' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zm33.414-6l5.95-5.95L45.95.636 40 6.586 34.05.636 32.636 2.05 38.586 8l-5.95 5.95 1.414 1.414L40 9.414l5.95 5.95 1.414-1.414L41.414 8zM40 48c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6zM9.414 40l5.95-5.95-1.414-1.414L8 38.586l-5.95-5.95L.636 34.05 6.586 40l-5.95 5.95 1.414 1.414L8 41.414l5.95 5.95 1.414-1.414L9.414 40z' fill='%231b1924' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
      }
    }

    .prose {
      @apply text-gray-100;
    }
  }
}

@layer components {
  #hero {
    @apply pt-4 pb-16 md:py-8;

    picture {
      @apply w-full md:col-span-full md:row-start-1;
    }
  }
  #intro {
    @apply py-16 md:py-32;
  }

  #menu,
  #menu__mobile {
    ul:first-of-type {
      a {
        @apply relative transition duration-300 px-4 rounded-xl;
        @apply hocus:layer-overlay-high hocus:text-gray-900;
        @apply focus:ring-2 focus:ring-gray-400;
        @apply dark:hocus:text-gray-100;

        &[aria-current="page"],
        &[data-state="active"] {
          @apply text-gray-900;
          @apply after:block after:rounded after:transition-colors after:bg-gray-900;
          @apply dark:text-gray-100;
          @apply dark:after:bg-gray-100;
        }
      }
    }

    ul:last-of-type a,
    .mode-button {
      @apply flex items-center justify-center transition duration-300 rounded-xl focus:layer-overlay-high focus:ring-2 focus:ring-gray-400 dark:hocus:text-gray-100;
    }
  }

  #menu {
    ul:first-of-type {
      a {
        @apply block py-2;

        &[aria-current="page"],
        &[data-state="active"] {
          @apply after:absolute after:w-1 after:h-1 after:left-1/2 after:-translate-x-1/2;
        }
      }
    }

    ul:last-of-type a,
    .mode-button {
      @apply w-10 h-10 p-2;
    }
  }

  #menu__mobile {
    ul:first-of-type {
      a {
        @apply flex items-center justify-between w-full my-2 py-3;

        &[aria-current="page"],
        &[data-state="active"] {
          @apply after:w-2 after:h-2;
        }
      }
    }

    ul:last-of-type a,
    .mode-button {
      @apply h-12 w-12 p-3;
    }
  }

  .btn {
    @apply inline-block my-3 py-2 px-4 rounded-xl transition duration-300 font-display font-bold text-xl !text-white bg-gray-900;
    @apply hocus:scale-105 hocus:text-white hocus:shadow-md focus:ring-4 focus:ring-gray-600;
  }

  .chip {
    @apply inline-block py-1 px-4 rounded-full bg-gray-900 font-bold text-white;
    @apply dark:bg-gray-100 dark:text-gray-900;

    &-group {
      @apply flex flex-wrap gap-2;

      &-center {
        @apply justify-center;
      }
    }
  }

  .cta {
    @apply layer-surface rounded-xl transition duration-300 max-w-4xl my-16 px-4 py-8 md:px-8 md:py-12 mx-auto hocus-within:scale-105;

    .btn {
      @apply mt-6 text-2xl;
    }

    &.highlight {
      @apply bg-yellow-500;

      h2 {
        @apply text-gray-900;
        @apply after:bg-blue-600;
      }

      p {
        @apply text-gray-900;
      }
    }
  }

  .exp-item {
    @apply relative mb-4 last:mb-0 py-2 pl-4;
    @apply before:block before:absolute before:w-1 before:h-full before:left-0 before:top-0 before:rounded-xl before:bg-blue-600;

    p {
      @apply mb-2 last:mb-0;
    }
  }

  .feature-item {
    picture {
      @apply w-full md:self-center lg:col-span-2 rounded-xl;
    }

    &:nth-child(odd) {
      picture {
        @apply md:order-2;
      }
    }
  }

  .tagline {
    @apply rounded-xl shadow-md overflow-hidden mx-auto mb-8 px-4 py-8 md:px-8 bg-gray-900 text-white;

    &.prose-tagline {
      @apply max-w-65ch;
    }

    p {
      @apply text-xl;
    }
  }
}
