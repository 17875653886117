/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 7, 2021 */

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-thinitalic-webfont.eot");
  src: url("/fonts/strawford-thinitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-thinitalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-thinitalic-webfont.woff") format("woff"),
    url("/fonts/strawford-thinitalic-webfont.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-regularitalic-webfont.eot");
  src: url("/fonts/strawford-regularitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-regularitalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-regularitalic-webfont.woff") format("woff"),
    url("/fonts/strawford-regularitalic-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-mediumitalic-webfont.eot");
  src: url("/fonts/strawford-mediumitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-mediumitalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-mediumitalic-webfont.woff") format("woff"),
    url("/fonts/strawford-mediumitalic-webfont.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-lightitalic-webfont.eot");
  src: url("/fonts/strawford-lightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-lightitalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-lightitalic-webfont.woff") format("woff"),
    url("/fonts/strawford-lightitalic-webfont.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-extralightitalic-webfont.eot");
  src: url("/fonts/strawford-extralightitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-extralightitalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-extralightitalic-webfont.woff") format("woff"),
    url("/fonts/strawford-extralightitalic-webfont.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-bolditalic-webfont.eot");
  src: url("/fonts/strawford-bolditalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-bolditalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-bolditalic-webfont.woff") format("woff"),
    url("/fonts/strawford-bolditalic-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "strawford";
  src: url("/fonts/strawford-blackitalic-webfont.eot");
  src: url("/fonts/strawford-blackitalic-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/strawford-blackitalic-webfont.woff2") format("woff2"),
    url("/fonts/strawford-blackitalic-webfont.woff") format("woff"),
    url("/fonts/strawford-blackitalic-webfont.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
